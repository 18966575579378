import axios from 'axios';
import {
    API_PERFORMANCE_AREAS_AND_LIMITS,
    API_PERFORMANCE_MODELS,
    API_PERFORMANCE_REARRANGEMENT, API_PERFORMANCE_SCOPED_DATA, API_PERFORMANCE_SUMMARY_DATA,
    API_ARMATURE_ATTACHMENTS, API_PERFORMANCE_MODES,
    API_SETTINGS, API_ELECTRICAL_SCHEMES, API_APPEAL, API_POLICY_COOKIES, API_POLICY_PRIVATE
} from '@/types/api.types';

export default class APIService {
    static async getCookiesPolicy() {
        const { data } = await axios.get(API_POLICY_COOKIES);

        return data['text'];
    }

    static async getPrivatePolicy() {
        const { data } = await axios.get(API_POLICY_PRIVATE);

        return data['text'];
    }

    static async saveAppeal(
        name,
        email,
        phone,
        company = null,
        comment = null,
        configuration = null
    ) {
        await axios.post(API_APPEAL, {
            name,
            email,
            phone,
            company,
            comment,
            'configuration_id': configuration
        });
    }

    static async getElectricalSchemes(size_id, power_id) {
        const { data } = await axios.post(API_ELECTRICAL_SCHEMES, {
            size_id,
            power_id
        });

        return data;
    }

    static async getArmatureAttachments(size_id, flange) {
        const { data } = await axios.post(API_ARMATURE_ATTACHMENTS, {
            size_id,
            flange
        });

        return data;
    }

    static async getSettings() {
        const { data } = await axios.get(API_SETTINGS);

        return data;
    }

    static async getSummaryData(
        mode,
        rearrangement,
        designation,
        limitMin,
        limitMax,
        powerId,
        additionalToolId,
        electricalSchemaId,
        connectionFlange,
        connectionTitle,
        protectionId,
        climaticId,
        workingStrokeId
    ) {
        const { data } = await axios.post(API_PERFORMANCE_SUMMARY_DATA, {
            mode,
            rearrangement,
            'designation': designation.replace(' об/мин', ''),
            'limit': {
                'min': limitMin,
                'max': limitMax
            },
            electrical_power_id: powerId,
            additional_tool_id: additionalToolId,
            electrical_scheme_id: electricalSchemaId,
            armature_flange_size: connectionFlange,
            armature_title: connectionTitle,
            protection_degree_id: protectionId,
            climatic_performance_id: climaticId,
            working_stroke_id: workingStrokeId
        });

        return data;
    }

    static async getScopedData(
        sizeId,
        rearrangement,
        designation,
        mode,
        limitMin = '0',
        limitMax = '0'
    ) {
        const { data } = await axios.post(API_PERFORMANCE_SCOPED_DATA, {
            'size_id': sizeId,
            rearrangement,
            'designation': designation.replace(' об/мин', ''),
            mode,
            'limit': {
                'min': limitMin,
                'max': limitMax
            }
        });

        return data;
    }

    static async getModes(
        sizeId,
        rearrangement,
        designation,
        limitMin = '0',
        limitMax = '0'
    ) {
        const { data } = await axios.post(API_PERFORMANCE_MODES, {
            'size_id': sizeId,
            rearrangement,
            'designation': designation.replace(' об/мин', ''),
            'limit': {
                'min': limitMin,
                'max': limitMax
            }
        });

        return data;
    }

    static async loadRearrangement(sizeId, model, limitMin = '0', limitMax = '0') {
        const { data } = await axios.post(API_PERFORMANCE_REARRANGEMENT, {
            'size_id': sizeId,
            'designation': model.replace(' об/мин', ''),
            'limit': {
                'min': limitMin,
                'max': limitMax
            }
        });

        return data;
    }

    static async loadModelsAreas(
        turn,
        type = null,
        area = null,
        torque = null,
        rearrangement = null,
        armature = null
    ) {
        if (type === null) {
            switch (turn) {
                case 'многооборотный':
                    type = ['задвижка', 'клапан'];
                    break;
                default:
                    type = ['шаровый кран', 'затвор дисковый'];
            }
        } else {
            type = [type]
        }

        let request = {
            'types': type
        };

        if (area !== null) {
            request['area'] = area;
        }

        if (torque !== null) {
            request['torque'] = Number(torque);
        }

        if (rearrangement !== null) {
            request['rearrangement'] = Number(rearrangement);
        }

        if (armature !== null) {
            request['armature'] = {
                flange: armature.flange,
                title: armature.title,
                connection: armature.connection
            };
        }

        const { data } = await axios.post(API_PERFORMANCE_MODELS, request);

        return data;
    }

    static async loadAreasAndLimits(designation) {
        const { data } = await axios.post(API_PERFORMANCE_AREAS_AND_LIMITS, {
            designation
        });

        const limits = [];

        if (data.limits.length > 0) {
            data.limits.map((limit) => {
                let value = '-';

                if ((limit.min == 0 && limit.max == 0) || (limit.min.length === 0 && limit.max.length === 0)) {
                    value = '-';
                } else if (limit.min == limit.max) {
                    value = limit.min;
                } else if (limit.min == 0 || limit.min.length === 0) {
                    value = 'до ' + limit.max;
                } else if (limit.max == 0 || limit.max.length === 0) {
                    value = 'от ' + limit.min;
                } else {
                    value = limit.min + ' - ' + limit.max;
                }

                limits.push({
                   value,
                   min: limit.min,
                   max: limit.max
               });
            });
        }

        return {
            areas: data.areas,
            limits
        };
    }
}
